.keen-slider {
  display: block;
  overflow: hidden;
  position: relative;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}
.keen-slider__slide {
  position: absolute;
  overflow: hidden;
  width: 100%;
  min-height: 100%;
}
.keen-slider--vertical .keen-slider__slide {
  min-height: auto;
}